@use "../util" as u;

.header {
  width: 100%;
  transition: background-color 1s ease, position 2s ease;
  background-color: transparent;
  position: fixed;
  z-index: 3;
  top: 0;
}

.header-box {
  width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-box {
  width: min(40%, 180px);
  height: fit-content;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.nav-box nav ul li {
  list-style: none;
}

.nav-box nav ul {
  display: flex;
  gap: 50px;
}

.nav-box a {
  font-family: "helveticaNeueLtStd-Ex";
  letter-spacing: 1.3px;
  color: u.$hamberger-orange;
  font-weight: bold;
  text-decoration: none;
}

.nav-box-mobile {
  display: none;
}

.nav-hamberger {
  display: none;
}

.nav-hamberger-line {
  width: 100%;
  height: 2px;
  border-radius: 50px;
  background-color: u.$hamberger-line;
  position: absolute;
  transition: background-color 2s ease;
}

.nav-hamberger-line--one {
  top: 0;
  transition: transform 1s ease, top 0.5s ease, background-color 1s ease;
  transform-origin: center;
}

.nav-hamberger-line--one.navup {
  top: 4px;
  transform-origin: center;
  transform: rotate(50deg);
  background-color: white;
  transition: transform 1s, top 0.5s ease, background-color 1s ease;
}

.nav-hamberger-line--two {
  bottom: 0;
  transform-origin: center;
  transition: transform 1s ease, bottom 0.5s ease, background-color 1s ease;
}

.nav-hamberger-line--two.navup {
  bottom: 4px;
  transform-origin: center;
  transform: rotate(-50deg);
  background-color: white;
  transition: transform 1s, top 0.5s ease, background-color 1s ease;
}

.header.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: transparent;
  transition: background-color 1s ease, position 2s ease;
}
