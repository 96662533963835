.eight-part {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  &__partnership {
    width: 250px;
    height: 150px;
  }

  &__partnership > a > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
