@use "../util" as u;

.six-part {
  width: 100vw;

  &__heading-container {
    width: 100vw;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    margin-bottom: 50px;
  }

  &__heading-box {
    width: 90%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__head-box--one {
    padding: 20px;
    width: fit-content;
    overflow: hidden;
    border-right: 1px solid u.$hamberger-orange;
  }

  &__head-box--two {
    padding: 20px;
    width: fit-content;
    overflow: hidden;
    border-left: 1px solid u.$hamberger-orange;
  }

  &__heading {
    font-family: "helveticaNeueLtStd-BdEx";
    color: rgb(53, 53, 53);
    font-size: clamp(1.5rem, 3vw, 3rem);
  }

  &__heading--one {
    transform: translateX(100%);
    transition: transform 1s ease;
  }

  &__heading--one.trans-one {
    transform: translateX(0);
    transition: transform 1s ease;
  }

  &__heading--two {
    transform: translateX(-100%);
    transition: transform 1s ease;
  }

  &__heading--two.trans-two {
    transform: translateX(0);
    transition: transform 1s ease;
  }

  &__main {
    width: 90%;
    margin: 0 auto;
  }

  &__main-txt > p {
    width: 50%;
    margin: 0 auto;
    font-family: "helveticaNeueLtStd-BdEx";
    font-size: clamp(1.5rem, 4vw, 4rem);
    text-transform: uppercase;
    color: u.$main-blue;
    margin-bottom: 100px;
    text-align: center;
  }

  &__main-checkout {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
  }

  &__main-checkout p {
    font-size: clamp(1rem, 2vw, 2rem);
    font-family: "helveticaNeueLtStd-Ex";
    color: u.$hamberger-orange;
  }

  &__main-arrow-up {
    width: 50px;
    height: 50px;
  }

  &__main-arrow-up img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__main-img-box {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &__main-txt-container {
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 10px;
    background-color: u.$hamberger-line;
    position: relative;
    overflow: hidden;
    flex-basis: 100%;
    width: 100%;
  }

  &__main-txt-container::before {
    content: " ";
    position: absolute;
    top: var(--y);
    left: var(--x);
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    background-color: u.$hamberger-orange;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
  }

  &__main-txt-container:hover::before {
    width: 1000px;
    height: 1000px;
  }

  &__main-col-1-img-box {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
  }

  &__main-txt-container-col-1 {
    position: relative;
    margin-bottom: 25px;
  }

  &__main-col-1-img-box img {
    width: 50px;
  }

  &__main-txt-container-col-2 {
    position: relative;
  }

  &__main-txt-container-col-1 p {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1rem, 1.5vw, 2rem);
    color: white;
  }

  &__main-txt-line {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__main-txt-line img {
    width: 25px;
  }

  &__main-txt-line p {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(0.5rem, 0.8vw, 1rem);
    line-height: 1.5;
    color: white;
    letter-spacing: 1.2px;
  }

  &__main-txt-line:nth-child(4) {
    margin-bottom: 0;
  }
}
