@font-face {
  font-family: helveticaNeueLtStd-Ex;
  src: url("HelveticaNeueLTStd-Ex.b44f717f.otf");
}

@font-face {
  font-family: helveticaRegular;
  src: url("Helvetica.ff598533.ttf");
}

@font-face {
  font-family: helveticaNeueLtStd-BdEx;
  src: url("HelveticaNeueLTStd-BdEx.ce8ab328.otf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  cursor: none;
  background-color: #fff;
  font-family: helveticaRegular;
  font-size: 62.5%;
  text-decoration: none;
  overflow-x: hidden;
}

.custome-cursor {
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 99;
  border: 1px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  position: fixed;
  left: 10px;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.custome-cursor__inner {
  pointer-events: none;
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 99;
  background-color: #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: width .5s, height .5s;
  position: fixed;
}

.custome-cursor__inner.increase {
  width: 50px;
  height: 50px;
  transition: width .5s, height .5s;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #004ffe;
}

.header {
  z-index: 3;
  background-color: #0000;
  width: 100%;
  transition: background-color 1s, position 2s;
  position: fixed;
  top: 0;
}

.header-box {
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.logo-box {
  width: min(40%, 180px);
  height: fit-content;
}

.logo-box__img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.nav-box nav ul li {
  list-style: none;
}

.nav-box nav ul {
  gap: 50px;
  display: flex;
}

.nav-box a {
  letter-spacing: 1.3px;
  color: #e75810;
  font-family: helveticaNeueLtStd-Ex;
  font-weight: bold;
  text-decoration: none;
}

.nav-box-mobile, .nav-hamberger {
  display: none;
}

.nav-hamberger-line {
  background-color: #181f4c;
  border-radius: 50px;
  width: 100%;
  height: 2px;
  transition: background-color 2s;
  position: absolute;
}

.nav-hamberger-line--one {
  transform-origin: center;
  transition: transform 1s, top .5s, background-color 1s;
  top: 0;
}

.nav-hamberger-line--one.navup {
  transform-origin: center;
  background-color: #fff;
  transition: transform 1s, top .5s, background-color 1s;
  top: 4px;
  transform: rotate(50deg);
}

.nav-hamberger-line--two {
  transform-origin: center;
  transition: transform 1s, bottom .5s, background-color 1s;
  bottom: 0;
}

.nav-hamberger-line--two.navup {
  transform-origin: center;
  background-color: #fff;
  transition: transform 1s, top .5s, background-color 1s;
  bottom: 4px;
  transform: rotate(-50deg);
}

.header.sticky {
  z-index: 3;
  background-color: #0000;
  transition: background-color 1s, position 2s;
  position: sticky;
  top: 0;
}

.hero-container {
  height: calc(100vh - 122px);
  margin-top: 122px;
  position: relative;
}

.hero-container__img-box {
  width: 100vw;
  height: 100%;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.hero-container__img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  transform: scale(1.4);
}

.hero-container__main-txt-container {
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
}

.hero-container__second-txt-container {
  width: 30%;
  height: 30%;
  position: relative;
}

.hero-container__txt {
  color: #fff;
  letter-spacing: 1.2px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(2rem, 4vw, 4rem);
  display: none;
}

.hero-container__txt--one, .hero-container__txt--two {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-container__txt--three {
  font-family: helveticaNeueLtStd-Ex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.second-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.second-container__txt-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  display: flex;
}

.second-container__txt-ani-box {
  width: 100%;
  overflow-y: hidden;
}

.second-container__txt-span {
  color: #004ffe;
}

.second-container__txt {
  text-align: center;
  width: 100%;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1.5rem, 2vw, 3rem);
  line-height: 1.5;
  transition: transform 1s;
}

.second-container__scroll-box {
  white-space: nowrap;
  gap: 20px;
  transition: transform 2s;
  display: flex;
}

.second-container__scroll-box p {
  letter-spacing: 1.1px;
  color: #fff;
  text-shadow: 0 0 2px #bdbdbd, 0 0 2px #747474;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(3rem, 5vw, 8rem);
}

.second-container__scroll-box--two {
  white-space: nowrap;
  justify-content: flex-end;
  gap: 20px;
  transition: transform 2s;
  display: flex;
}

.second-container__scroll-box--two p {
  letter-spacing: 1.1px;
  color: #fff;
  text-shadow: 0 0 2px #bdbdbd, 0 0 2px #747474;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(3rem, 5vw, 8rem);
}

.second-container__scroll-box-container {
  width: 100%;
  overflow: hidden;
}

.second-container__charm {
  width: 20px;
  height: 20px;
  margin-top: 50px;
}

.add-transform {
  transition: transform 1s;
  transform: translateY(0);
}

.third-part {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.third-part__img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.third-part__txt-box {
  z-index: 1;
  position: absolute;
  bottom: 50px;
  left: 50px;
}

.third-part__txt {
  color: #fff;
  letter-spacing: 1.2px;
  font-family: helveticaNeueLtStd-Ex;
}

.third-part__txt--one {
  font-size: clamp(1rem, 2vw, 2rem);
  line-height: 1.5;
  text-decoration-line: underline;
}

.third-part__txt--two {
  font-size: clamp(.5rem, .6vw, 1rem);
}

.third-part__cursormover {
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}

.third-part__cursormover.increase {
  transform-origin: center;
  transform: scale(2);
}

.fourth-part {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.fourth-part__img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.fourth-part__txt-box {
  z-index: 1;
  position: absolute;
  bottom: 50px;
  left: 50px;
}

.fourth-part__txt {
  color: #fff;
  letter-spacing: 1.2px;
  font-family: helveticaNeueLtStd-Ex;
}

.fourth-part__txt--one {
  font-size: clamp(1rem, 2vw, 2rem);
  line-height: 1.5;
  text-decoration-line: underline;
}

.fourth-part__txt--two {
  font-size: clamp(.5rem, .6vw, 1rem);
}

.five-part {
  width: 100vw;
  max-height: 100vh;
}

.five-part__container {
  grid-template-rows: 50vh 50vh;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "b1 b2"
                       "b1 b3";
  min-width: 0;
  min-height: 0;
  display: grid;
}

.five-part__img-box {
  min-width: 0;
  min-height: 0;
  position: relative;
}

.five-part__txt-box {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.five-part__txt-box p {
  color: #fff;
  font-family: helveticaNeueLtStd-Ex;
}

.five-part__txt-box p:first-child {
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-size: clamp(1rem, 1vw, 1.5rem);
  text-decoration: underline;
}

.five-part__txt-box p:nth-child(2) {
  letter-spacing: 1.5px;
  font-size: clamp(.3rem, .5vw, 1rem);
}

.five-part__img-box img, .five-part__img-box {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.five-part__video-box {
  width: 100%;
  height: 100%;
}

.five-part__video-box video {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.five-part__img-box--one {
  grid-area: b1;
}

.five-part__img-box--two {
  grid-area: b2;
}

.five-part__img-box--three {
  grid-area: b3;
  grid-template-rows: 50vh;
  grid-template-columns: 1fr 1fr;
  max-height: 100%;
  display: grid;
}

.five-part__img-subgrid-box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.five-part__img-sub {
  position: relative;
}

.six-part {
  width: 100vw;
}

.six-part__heading-container {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 20%;
  margin-bottom: 50px;
  padding-top: 50px;
  display: flex;
}

.six-part__heading-box {
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 50%;
  display: flex;
}

.six-part__head-box--one {
  border-right: 1px solid #e75810;
  width: fit-content;
  padding: 20px;
  overflow: hidden;
}

.six-part__head-box--two {
  border-left: 1px solid #e75810;
  width: fit-content;
  padding: 20px;
  overflow: hidden;
}

.six-part__heading {
  color: #353535;
  font-family: helveticaNeueLtStd-BdEx;
  font-size: clamp(1.5rem, 3vw, 3rem);
}

.six-part__heading--one {
  transition: transform 1s;
  transform: translateX(100%);
}

.six-part__heading--one.trans-one {
  transition: transform 1s;
  transform: translateX(0);
}

.six-part__heading--two {
  transition: transform 1s;
  transform: translateX(-100%);
}

.six-part__heading--two.trans-two {
  transition: transform 1s;
  transform: translateX(0);
}

.six-part__main {
  width: 90%;
  margin: 0 auto;
}

.six-part__main-txt > p {
  text-transform: uppercase;
  color: #004ffe;
  text-align: center;
  width: 50%;
  margin: 0 auto 100px;
  font-family: helveticaNeueLtStd-BdEx;
  font-size: clamp(1.5rem, 4vw, 4rem);
}

.six-part__main-checkout {
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
  display: flex;
}

.six-part__main-checkout p {
  color: #e75810;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 2vw, 2rem);
}

.six-part__main-arrow-up {
  width: 50px;
  height: 50px;
}

.six-part__main-arrow-up img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.six-part__main-img-box {
  gap: 20px;
  width: 100%;
  display: flex;
}

.six-part__main-txt-container {
  background-color: #181f4c;
  flex-direction: column;
  flex-basis: 100%;
  gap: 10px;
  width: 100%;
  padding: 25px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.six-part__main-txt-container:before {
  content: " ";
  top: var(--y);
  left: var(--x);
  background-color: #e75810;
  border-radius: 50%;
  width: 0;
  height: 0;
  transition: width .5s, height .5s;
  position: absolute;
  transform: translate(-50%, -50%);
}

.six-part__main-txt-container:hover:before {
  width: 1000px;
  height: 1000px;
}

.six-part__main-col-1-img-box {
  gap: 20px;
  margin-bottom: 25px;
  display: flex;
}

.six-part__main-txt-container-col-1 {
  margin-bottom: 25px;
  position: relative;
}

.six-part__main-col-1-img-box img {
  width: 50px;
}

.six-part__main-txt-container-col-2 {
  position: relative;
}

.six-part__main-txt-container-col-1 p {
  color: #fff;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 1.5vw, 2rem);
}

.six-part__main-txt-line {
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  display: flex;
}

.six-part__main-txt-line img {
  width: 25px;
}

.six-part__main-txt-line p {
  color: #fff;
  letter-spacing: 1.2px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(.5rem, .8vw, 1rem);
  line-height: 1.5;
}

.six-part__main-txt-line:nth-child(4) {
  margin-bottom: 0;
}

.seven-part {
  width: 100vw;
  height: 100vh;
}

.seven-part__img-container {
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.seven-part__txt-box p {
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.seven-part__txt-box > p {
  margin-bottom: 20px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(.5rem, 1vw, 1rem);
}

.seven-part__img-box {
  width: min(100%, 500px);
  height: 400px;
}

.seven-part__img-box img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.seven-part__txt-overflow-box {
  white-space: nowrap;
  margin-bottom: 30px;
  display: flex;
  overflow: hidden;
}

.seven-part__txt-transform {
  padding-right: 10px;
}

.seven-part__txt-overflow-box:hover .seven-part__txt-transform {
  animation-name: RightToLeft;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes RightToLeft {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translate(-100%);
  }
}

.footer {
  width: 90%;
  margin: 20px auto;
}

.footer__container {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.footer__text p:first-child {
  letter-spacing: 1.2px;
  margin-bottom: 8px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(.7rem, 1vw, 1rem);
}

.footer__text p:nth-child(2), .footer__text a > p:first-child {
  margin-bottom: 8px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 1.5vw, 2rem);
}

.footer > p {
  text-align: center;
  margin-top: 20px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(.5rem, 1vw, .8rem);
}

.mywork {
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;
  width: 90%;
  margin: 122px auto;
  display: grid;
}

.mywork__box {
  border: 1px solid #0000;
}

.mywork__box-txt-flex {
  aspect-ratio: 16 / 9;
  align-items: center;
  width: 100%;
  display: flex;
}

.mywork__box-heading {
  width: fit-content;
  margin-bottom: 5px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: 1.3rem;
  text-decoration: underline;
}

.mywork__box-subhead {
  width: fit-content;
  font-family: helveticaNeueLtStd-Ex;
  font-size: .8rem;
}

.mywork__box-img {
  aspect-ratio: 16 / 9;
  width: 100%;
  transition: transform 1s;
  overflow: hidden;
}

.mywork__box-img-container {
  width: 100%;
  height: 100%;
  transition: transform 1s;
}

.mywork__box-img img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  transition: transform 1s;
}

.mywork__box-img-container:hover {
  transition: transform 1s;
  transform: scale(1.4);
}

.mywork__product-txt {
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(2.2rem, 3.8vw, 3.8rem);
}

.schedule {
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.schedule__head {
  text-align: center;
  margin-bottom: 10px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1.5rem, 3.5vw, 3.5rem);
  font-weight: 100;
}

.schedule__call {
  text-align: center;
  color: #004ffe;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 2vw, 2rem);
  text-decoration: underline;
}

.schedule__txt {
  width: fit-content;
}

.schedule__call-container {
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  overflow: hidden;
}

.schedule__call-container:hover .schedule__call-box {
  animation-name: righttoleft;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes righttoleft {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.schedule__call-box {
  gap: 10px;
  padding-right: 10px;
  display: flex;
}

.schedule__call-box img {
  aspect-ratio: 16 / 15;
  width: 40px;
  display: block;
}

a {
  color: #000;
  text-decoration: none;
}

.inner-page__overlay {
  z-index: 1;
  background-color: #0000004d;
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
}

.inner-page__img-box {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  position: relative;
}

.inner-page__img-box--click {
  cursor: pointer;
}

.inner-page__grid {
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-auto-rows: 600px;
  display: grid;
}

.inner-page__grid-img-box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.inner-page__txt-box {
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  margin-bottom: 120px;
  display: flex;
}

.inner-page__overlapfixed {
  z-index: 1;
  aspect-ratio: 16 / 9;
  width: 0;
  animation-name: overlapanim;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  display: block;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.inner-page__overlapfixed img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.inner-page__overlapclose {
  z-index: 2;
  animation-name: overlapcloseAnim;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  display: none;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.inner-page__overlapfixed-closed-btn {
  text-align: right;
  cursor: pointer;
  font-size: 1rem;
}

.inner-page__overlapfixed-closed-btn p {
  color: #fff;
  font-size: 2rem;
  transform: translateX(30px);
}

@keyframes overlapanim {
  0% {
    aspect-ratio: 16 / 9;
    width: 0;
    position: fixed;
  }

  100% {
    aspect-ratio: 25 / 26;
    width: min(100%, 650px);
    position: fixed;
  }
}

@keyframes overlapcloseAnim {
  0% {
    aspect-ratio: 16 / 20;
    width: min(100%, 600px);
    position: fixed;
  }

  100% {
    aspect-ratio: 16 / 9;
    width: 0;
    position: fixed;
  }
}

.inner-main-div {
  width: 90%;
  margin: 0 auto;
}

.inner-page__main {
  aspect-ratio: 16 / 9;
  width: 100%;
  margin-top: 122px;
}

.inner-page__img-box {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.inner-page__img-box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  display: block;
}

.inner-page__heading {
  width: fit-content;
  margin: 30px 0;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(2rem, 2.5vw, 2.5rem);
  font-weight: 100;
  text-decoration: underline;
}

.inner-page__brand-name {
  width: fit-content;
  margin-bottom: 30px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  line-height: 1.5;
}

.inner-page__txt-box {
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;
}

.inner-page__web-link {
  color: #fff;
  letter-spacing: 1.3px;
  background-color: #004ffe;
  border-radius: 50px;
  padding: 20px 30px;
  font-size: clamp(.5rem, 1.1vw, 1.1rem);
  display: inline-block;
}

.inner-page__para-box {
  width: min(100%, 800px);
}

.inner-page__para-box p:first-child, .inner-page__para-box p:nth-child(2), .inner-page__para-box p:nth-child(3) {
  margin-bottom: 20px;
  font-size: clamp(1rem, 1vw, 1.2rem);
  line-height: 1.5;
}

.inner-page__para-box p:nth-child(4) {
  font-size: clamp(1rem, 1vw, 1.2rem);
  line-height: 1.5;
}

.packaging-inner-page__grid {
  gap: 20px;
  display: flex;
}

.packaging-inner-page__img-box--one {
  width: min(100%, 1000px);
  height: 1200px;
}

.packaging-inner-page__img-box--two {
  width: min(100%, 1500px);
  height: 1200px;
  margin-bottom: 20px;
}

.packaging-inner-page__img-box--one img, .packaging-inner-page__img-box--two img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.video-editing {
  width: 100%;
  margin-top: 122px;
}

.video-editing__img-box {
  aspect-ratio: 16 / 7;
  width: 100%;
  position: relative;
}

.video-editing__img-box video {
  object-fit: fill;
  object-position: center;
  width: 100%;
  height: 100%;
}

.video-editing__video-play-btn {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-editing__video-icon-play {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.video-editing__video-icon-pause {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
  display: none;
}

.video-editing__txt-box {
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto 200px;
  display: flex;
}

.video-editing__txt-one {
  flex-basis: min(100%, 600px);
}

.video-editing__txt-one p {
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 1.1vw, 1.1rem);
  line-height: 1.8;
}

.video-editing__txt-one p:first-child {
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: clamp(1rem, 3vw, 3rem);
  text-decoration: underline;
}

.video-editing__txt-two {
  flex-basis: min(100%, 600px);
}

.video-editing__txt-two p:first-child {
  letter-spacing: 1px;
  font-family: helveticaRegular;
  font-size: clamp(.8rem, 1vw, 1rem);
  line-height: 1.6;
  text-decoration: none;
}

.video-editing__second-img-box {
  display: flex;
}

.video-editing__second-img-container {
  aspect-ratio: 16 / 9;
  width: 100%;
  position: relative;
}

.video-editing__second-img-container video {
  object-fit: contain;
  object-position: top;
  width: 100%;
  height: min-content;
}

.video-editing__second-btn-box {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-40%, -50%);
}

.video-editing__second-btn-box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.video-editing__second-btn-pause {
  display: none;
}

.video-editing__second-img-container--one {
  aspect-ratio: 16 / 20;
}

.contact {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 250px;
  display: flex;
}

.contact__contact-heading {
  margin-bottom: 20px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: 2rem;
}

.contact__container {
  width: 70%;
  margin: 0 auto;
}

.contact__form-box {
  background-color: #ebebeb;
  justify-content: space-between;
  margin-bottom: 300px;
  display: flex;
}

.contact__form {
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.contact__form input {
  padding: 20px;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.contact__form input, .contact textarea {
  border: none;
  width: 100%;
  font-family: helveticaNeueLtStd-Ex;
  display: inline-block;
}

.contact__form textarea {
  resize: none;
  height: 100px;
  padding: 20px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.contact__sbt-btn {
  color: #fff;
  letter-spacing: 1.3px;
  background-color: #000;
  border-radius: 20px;
  padding: 10px 20px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: 1.2rem;
}

.contact__sbt-btn:hover {
  cursor: pointer;
}

.contact__txt-box {
  background-color: #004ffe;
  width: min(100%, 350px);
  min-height: 100%;
}

.contact__txt-container {
  padding: 50px;
}

.contact__logo-txt {
  align-items: center;
  gap: 20px;
  margin-bottom: 80px;
  display: flex;
}

.contact__logo {
  background-color: #fff3;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.contact__txt {
  color: #fff;
  font-size: clamp(.8rem, 1vw, 1.2rem);
  line-height: 1.5;
}

.contact__logo img {
  object-fit: contain;
  object-position: center;
  width: 50%;
  height: 50%;
}

.contact__overlay {
  z-index: 3;
  background-color: #5c5c5c6b;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
}

.contact__overlay-box {
  background-color: #004ffe;
  width: 60%;
  margin: 0 auto;
}

.contact__overlay-txt {
  text-align: center;
  color: #fff;
  letter-spacing: 1.2px;
  padding: 0 10px 45px;
  font-family: helveticaNeueLtStd-Ex;
  font-size: clamp(2rem, 3vw, 3rem);
  line-height: 1.5;
}

.contact__overlay-btn-box {
  justify-content: flex-end;
  display: flex;
  transform: translateY(-45px);
}

.contact__overlay-btn {
  color: #fff;
  background-color: #0000;
  border: none;
  font-size: clamp(1.5rem, 3vw, 3rem);
}

input::placeholder, textarea::placeholder {
  color: #d1d1d18e;
}

.about {
  margin-top: 250px;
  margin-bottom: 300px;
}

.about__container {
  justify-content: space-between;
  gap: 50px;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.about__container-txt {
  flex-basis: 40%;
}

.about__txt-head {
  margin-bottom: 30px;
  font-family: helveticaRegular;
  font-size: clamp(2rem, 2.5vw, 2.5rem);
}

.about__txt-para {
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  line-height: 1.8;
}

.about__container-img {
  flex-basis: 50%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 50px;
  display: grid;
}

.about__image {
  aspect-ratio: 16 / 20;
  width: 100%;
  margin-bottom: 20px;
}

.about__image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.about__image-txt {
  text-align: right;
  font-family: helveticaNeueLtStd-Ex;
  font-size: 1.2rem;
}

.about__image-txt--subtxt {
  font-size: .8rem;
}

.eight-part {
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.eight-part__partnership {
  width: 250px;
  height: 150px;
}

.eight-part__partnership > a > img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

@media screen and (width <= 2560px) {
  .inner-page__grid {
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
    grid-auto-rows: 800px;
  }

  .inner-page__img-box img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    display: block;
  }
}

@media screen and (width <= 1920px) {
  .inner-page__grid {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 600px;
  }
}

@media screen and (width <= 1305px) {
  body {
    cursor: auto;
  }

  .six-part__main-img-box {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .six-part__main-txt-container {
    flex-basis: 90%;
  }
}

@media screen and (width <= 900px) {
  .six-part__main-txt-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media screen and (width <= 700px) {
  .header-box {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .nav-box {
    display: none;
  }

  .nav-box-mobile {
    z-index: 3;
    background-color: #004ffe;
    width: 100vw;
    height: 0;
    transition: height 1s;
    display: block;
    position: fixed;
    top: 0;
  }

  .nav-box-mobile.add-height {
    height: 100%;
    transition: height 1s;
    position: fixed;
  }

  .nav-hamberger {
    width: 20px;
    height: 10px;
    display: block;
    position: relative;
  }

  .nav-box-mobile {
    overflow-y: hidden;
  }

  .nav-box-mobile-nav {
    opacity: 0;
    padding: 80px 40px;
    transition: opacity 2s;
  }

  .nav-box-mobile-nav.display-one {
    opacity: 1;
    transition: opacity 2s;
  }

  .nav-box-mobile ul {
    flex-direction: column;
    gap: 60px;
    display: flex;
  }

  .nav-box-mobile li {
    list-style: none;
  }

  .nav-box-mobile a {
    color: #fff;
    font-family: helveticaNeueLtStd-Ex;
    font-size: clamp(1.2rem, 1.5vw, 3rem);
    text-decoration: none;
  }

  .third-part__cursormover {
    display: none;
  }

  .five-part__container {
    grid-template-rows: repeat(3, 30vh);
    grid-template-columns: 1fr;
    grid-template-areas: initial;
  }

  .five-part__img-box--one, .five-part__img-box--two {
    grid-area: initial !important;
  }

  .five-part__img-box--three {
    grid-template-rows: repeat(2, 20vh);
    grid-template-columns: 1fr;
    grid-area: initial !important;
  }

  .six-part__main {
    flex-direction: column;
  }

  .seven-part {
    margin-top: 60px;
  }

  .seven-part__img-container {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }

  .seven-part__img-box {
    height: 200px;
  }

  .footer__container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    display: flex;
  }

  .footer__text p:first-child, .footer__text p:nth-child(2) {
    text-align: center;
  }

  .custome-cursor, .custome-cursor__inner {
    display: none;
  }

  .mywork {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .packaging-inner-page__grid {
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
  }

  .packaging-inner-page__img-box--one {
    width: min(100%, 1000px);
    height: 300px;
  }

  .packaging-inner-page__img-box--two {
    width: min(100%, 1500px);
    height: 300px;
    margin-bottom: 20px;
  }

  .packaging-inner-page__img-box--one img, .packaging-inner-page__img-box--two img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .video-editing__second-img-box {
    flex-direction: column;
    display: flex;
  }

  .video-editing__video-play-btn {
    width: 50px;
    height: 50px;
  }

  .video-editing__txt-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
  }

  .contact__form-box {
    flex-direction: column;
  }

  .contact__logo-txt {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .about__container, .inner-page__txt-box {
    flex-direction: column;
  }

  .inner-page__grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .inner-page__overlapfixed-closed-btn p {
    transform: translateX(0);
  }
}
/*# sourceMappingURL=index.0060f5c5.css.map */
