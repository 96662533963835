@use "../util" as u;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: u.$font;
  font-size: u.$font-size;
  overflow-x: hidden;
  text-decoration: none;
  box-sizing: border-box;
  cursor: none;
  background-color: #fff;
  // background-color: red;
  font-family: "helveticaRegular";
}

.custome-cursor {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 10px;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
  mix-blend-mode: difference;
  border-radius: 50%;
  z-index: 99;
  overflow: hidden;

  &__inner {
    pointer-events: none;
    position: fixed;
    width: 10px;
    height: 10px;
    background-color: #fff;
    mix-blend-mode: difference;
    border-radius: 50%;
    pointer-events: none;
    transition: width 0.5s, height 0.5s;
    z-index: 99;
  }
  &__inner.increase {
    height: 50px;
    width: 50px;
    transition: width 0.5s, height 0.5s;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

// ::-webkit-scrollbar-track {
//   background-color: u.$hamberger-line;
// }

::-webkit-scrollbar-thumb {
  background-color: u.$main-blue;
}
