.fourth-part {
  width: 100vw;
  height: 100vh;
  position: relative;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__txt-box {
    position: absolute;
    bottom: 50px;
    left: 50px;
    z-index: 1;
  }

  &__txt {
    font-family: "helveticaNeueLtStd-Ex";
    color: white;
    letter-spacing: 1.2px;
  }

  &__txt--one {
    font-size: clamp(1rem, 2vw, 2rem);
    text-decoration-line: underline;
    line-height: 1.5;
  }

  &__txt--two {
    font-size: clamp(0.5rem, 0.6vw, 1rem);
  }
}
