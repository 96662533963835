.seven-part {
  width: 100vw;
  height: 100vh;

  &__img-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  &__txt-box p {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1rem, 1.5vw, 1.5rem);
  }

  &__txt-box > p {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(0.5rem, 1vw, 1rem);
    margin-bottom: 20px;
  }

  &__img-box {
    width: min(100%, 500px);
    height: 400px;
  }

  &__img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__txt-overflow-box {
    white-space: nowrap;
    margin-bottom: 30px;
    overflow: hidden;
    display: flex;
  }

  &__txt-transform {
    // display: flex;
    padding-right: 10px;
  }

  &__txt-overflow-box:hover &__txt-transform {
    animation-name: RightToLeft;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes RightToLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translate(-100%);
  }
}
