@use "../util/" as u;

.mywork {
  width: 90%;
  margin: 122px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;

  &__box {
    border: 1px solid transparent;
  }

  &__box-txt-flex {
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
  }

  &__box-heading {
    font-size: 1.3rem;
    font-family: "helveticaNeueLtStd-Ex";
    text-decoration: underline;
    margin-bottom: 5px;
    width: fit-content;
  }

  &__box-subhead {
    font-size: 0.8rem;
    font-family: "helveticaNeueLtStd-Ex";
    width: fit-content;
  }

  &__box-img {
    width: 100%;
    aspect-ratio: 16/9;
    transition: transform 1s ease;
    overflow: hidden;
    transition: transform 1s ease;
  }

  &__box-img-container {
    width: 100%;
    height: 100%;
    transition: transform 1s ease;
  }

  &__box-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 1s ease;
  }

  // &__box-img:hover {
  //   transform: scale(0.9);
  //   transition: transform 1s ease;
  // }

  &__box-img-container:hover {
    transform: scale(1.4);
    transition: transform 1s ease;
  }

  &__product-txt {
    font-size: clamp(2.2rem, 3.8vw, 3.8rem);
    font-family: "helveticaNeueLtStd-Ex";
  }
}

.schedule {
  position: relative;
  margin-bottom: 300px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__head {
    text-align: center;
    font-family: "helveticaNeueLtStd-Ex";
    font-weight: 100;
    font-size: clamp(1.5rem, 3.5vw, 3.5rem);
    margin-bottom: 10px;
  }

  &__call {
    font-size: clamp(1rem, 2vw, 2rem);
    font-family: "helveticaNeueLtStd-Ex";
    text-align: center;
    color: u.$main-blue;
    text-decoration: underline;
  }

  &__txt {
    width: fit-content;
  }

  &__call-container {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  &__call-container:hover &__call-box {
    animation-name: righttoleft;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes righttoleft {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }

  &__call-box {
    display: flex;
    gap: 10px;
    padding-right: 10px;
  }

  &__call-box img {
    display: block;
    width: 40px;
    aspect-ratio: 16/15;
  }
}

a {
  text-decoration: none;
  color: black;
}
