@use "../util/" as u;

.inner-main-div {
  width: 90%;
  margin: 0 auto;
}

.inner-page {
  &__main {
    width: 100%;
    aspect-ratio: 16/9;
    margin-top: 122px;
  }

  &__img-box {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  &__img-box img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
  }

  &__heading {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(2rem, 2.5vw, 2.5rem);
    font-weight: 100;
    margin: 30px 0 30px 0;
    width: fit-content;
    text-decoration: underline;
  }

  &__brand-name {
    line-height: 1.5;
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    margin-bottom: 30px;
    width: fit-content;
  }

  &__txt-box {
    margin-top: 120px;
    margin-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
  }

  &__web-link {
    display: inline-block;
    background-color: u.$main-blue;
    padding: 20px 30px;
    border-radius: 50px;
    font-size: clamp(0.5rem, 1.1vw, 1.1rem);
    color: white;
    letter-spacing: 1.3px;
  }

  &__para-box {
    width: min(100%, 800px);
  }

  &__para-box p:nth-child(1) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    margin-bottom: 20px;
    line-height: 1.5;
  }
  &__para-box p:nth-child(2) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__para-box p:nth-child(3) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__para-box p:nth-child(4) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    line-height: 1.5;
  }
}

.packaging-inner-page {
  &__grid {
    display: flex;
    gap: 20px;
  }

  &__img-box--one {
    width: min(100%, 1000px);
    height: 1200px;
  }

  &__img-box--two {
    width: min(100%, 1500px);
    height: 1200px;
    margin-bottom: 20px;
  }

  &__img-box--one img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__img-box--two img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
