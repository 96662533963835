.inner-main-div {
  width: 90%;
  margin: 0 auto;
}

.inner-page {
  &__main {
    width: 100%;
    aspect-ratio: 16/9;
    margin-top: 122px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: none;
  }

  &__img-box {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    position: relative;
  }

  &__img-box img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
  }

  &__img-box--click {
    cursor: pointer;
  }

  &__heading {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1rem, 2.5vw, 2.5rem);
    font-weight: 100;
    margin: 30px 0 30px 0;
    width: fit-content;
    text-decoration: underline;
  }

  &__brand-name {
    line-height: 1.5;
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(0.5rem, 0.8vw, 0.8rem);
    margin-bottom: 30px;
    width: fit-content;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-auto-rows: 600px;
  }

  &__grid-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__txt-box {
    margin-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
  }

  &__para-box {
    width: min(100%, 800px);
  }

  &__para-box p:nth-child(1) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    margin-bottom: 20px;
    line-height: 1.5;
  }
  &__para-box p:nth-child(2) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__para-box p:nth-child(3) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__para-box p:nth-child(4) {
    font-size: clamp(1rem, 1vw, 1.2rem);
    line-height: 1.5;
  }

  &__overlapfixed {
    position: absolute;
    z-index: 1;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
    width: 0px;
    aspect-ratio: 16/9;
    animation-name: overlapanim;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    display: block;
  }

  &__overlapfixed img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__overlapclose {
    position: absolute;
    z-index: 2;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
    animation-name: overlapcloseAnim;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    display: none;
  }

  &__overlapfixed-closed-btn {
    font-size: 1rem;
    text-align: right;
    cursor: pointer;
  }

  &__overlapfixed-closed-btn p {
    font-size: 2rem;
    color: white;
    transform: translateX(30px);
  }
}

@keyframes overlapanim {
  0% {
    position: fixed;
    width: 0px;
    aspect-ratio: 16/9;
  }
  100% {
    position: fixed;
    width: min(100%, 650px);
    aspect-ratio: 25/26;
  }
}

@keyframes overlapcloseAnim {
  0% {
    position: fixed;
    width: min(100%, 600px);
    aspect-ratio: 16/20;
  }
  100% {
    position: fixed;
    width: 0px;
    aspect-ratio: 16/9;
  }
}
