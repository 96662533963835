.about {
  margin-top: 250px;
  margin-bottom: 300px;

  &__container {
    width: 90%;
    margin: 0 auto;
  }

  &__container {
    display: flex;
    gap: 50px;
    justify-content: space-between;
  }

  &__container-txt {
    flex-basis: 40%;
  }

  &__txt-head {
    font-size: clamp(2rem, 2.5vw, 2.5rem);
    margin-bottom: 30px;
    font-family: "helveticaRegular";
  }

  &__txt-para {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    line-height: 1.8;
  }

  &__container-img {
    flex-basis: 50%;
  }

  &__container-img {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 50px;
  }

  &__image {
    width: 100%;
    aspect-ratio: 16/20;
    margin-bottom: 20px;
  }

  &__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__image-txt {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: 1.2rem;
    text-align: right;
  }

  &__image-txt--subtxt {
    font-size: 0.8rem;
  }
}
