$font: "Montserrat", sans-serif;
$font-size: 62.5%;

@font-face {
  font-family: "helveticaNeueLtStd-Ex";
  src: url("../fonts/HelveticaNeueLTStd-Ex.otf");
}

@font-face {
  font-family: "helveticaRegular";
  src: url("../fonts/Helvetica.ttf");
}

@font-face {
  font-family: "helveticaNeueLtStd-BdEx";
  src: url("../fonts/HelveticaNeueLTStd-BdEx.otf");
}
