@use "../util" as u;

.second-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__txt-box {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__txt-ani-box {
    width: 100%;
    overflow-y: hidden;
  }

  &__txt-span {
    color: u.$main-blue;
  }

  &__txt {
    width: 100%;
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1.5rem, 2vw, 3rem);
    text-align: center;
    transition: transform 1s ease;
    line-height: 1.5;
  }

  &__scroll-box {
    display: flex;
    gap: 20px;
    white-space: nowrap;
    transition: transform 2s ease;
  }

  &__scroll-box p {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(3rem, 5vw, 8rem);
    letter-spacing: 1.1px;
    color: white;
    text-shadow: 0px 0px 2px rgb(189, 189, 189), 0px 0px 2px rgb(116, 116, 116);
  }

  &__scroll-box--two {
    display: flex;
    gap: 20px;
    white-space: nowrap;
    justify-content: flex-end;
    transition: transform 2s ease;
  }

  &__scroll-box--two p {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(3rem, 5vw, 8rem);
    letter-spacing: 1.1px;
    color: white;
    text-shadow: 0px 0px 2px rgb(189, 189, 189), 0px 0px 2px rgb(116, 116, 116);
  }

  &__scroll-box-container {
    width: 100%;
    overflow: hidden;
  }

  &__charm {
    width: 20px;
    height: 20px;
    margin-top: 50px;
  }
}

.add-transform {
  transform: translateY(0px);
  transition: transform 1s ease;
}
