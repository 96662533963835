.footer {
  width: 90%;
  margin: 20px auto;
  &__container {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  &__text p:nth-child(1) {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(0.7rem, 1vw, 1rem);
    margin-bottom: 8px;
    letter-spacing: 1.2px;
  }

  &__text p:nth-child(2) {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1rem, 1.5vw, 2rem);
    margin-bottom: 8px;
  }

  &__text a > p:nth-child(1) {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1rem, 1.5vw, 2rem);
    margin-bottom: 8px;
  }
}

.footer > p {
  text-align: center;
  font-size: clamp(0.5rem, 1vw, 0.8rem);
  font-family: "helveticaNeueLtStd-Ex";
  margin-top: 20px;
}
