.video-editing {
  width: 100%;
  margin-top: 122px;

  &__img-box {
    width: 100%;
    aspect-ratio: 16/7;
    position: relative;
  }

  &__img-box video {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }

  &__video-play-btn {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__video-icon-play {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__video-icon-pause {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__txt-box {
    width: 90%;
    margin: 0 auto 200px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__txt-one {
    flex-basis: min(100%, 600px);
  }

  &__txt-one p {
    font-family: "helveticaNeueLtStd-Ex";
    line-height: 1.8;
    font-size: clamp(1rem, 1.1vw, 1.1rem);
  }

  &__txt-one p:nth-child(1) {
    font-size: clamp(1rem, 3vw, 3rem);
    margin-bottom: 25px;
    margin-top: 30px;
    text-decoration: underline;
  }

  &__txt-two {
    flex-basis: min(100%, 600px);
  }

  &__txt-two p:nth-child(1) {
    font-size: clamp(0.8rem, 1vw, 1rem);
    text-decoration: none;
    font-family: "helveticaRegular";
    line-height: 1.6;
    letter-spacing: 1px;
  }

  &__second-img-box {
    display: flex;
  }

  &__second-img-container {
    width: min(100%, 100%);
    aspect-ratio: 16/9;
    position: relative;
  }

  &__second-img-container video {
    width: 100%;
    height: min-content;
    object-fit: contain;
    object-position: top;
  }

  &__second-btn-box {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
  }

  &__second-btn-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__second-btn-pause {
    display: none;
  }

  &__second-img-container--one {
    aspect-ratio: 16/20;
  }
}
