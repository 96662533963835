@use "../util/" as u;

.contact {
  margin-top: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__contact-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    font-family: "helveticaNeueLtStd-Ex";
  }

  &__container {
    width: 70%;
    margin: 0 auto;
  }

  &__form-box {
    background-color: rgb(235, 235, 235);
    display: flex;
    justify-content: space-between;
    margin-bottom: 300px;
  }

  &__form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-end;
    flex-grow: 1;
  }

  &__form input {
    padding: 20px;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
  }

  &__form input,
  textarea {
    width: 100%;
    display: inline-block;
    border: none;
    font-family: "helveticaNeueLtStd-Ex";
  }

  &__form textarea {
    resize: none;
    height: 100px;
    padding: 20px;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-family: "helveticaNeueLtStd-Ex";
  }

  &__sbt-btn {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: black;
    color: white;
    font-family: "helveticaNeueLtStd-Ex";
    font-size: 1.2rem;
    letter-spacing: 1.3px;
  }

  &__sbt-btn:hover {
    cursor: pointer;
  }

  &__txt-box {
    width: min(100%, 350px);
    min-height: 100%;
    background-color: #004ffe;
  }

  &__txt-container {
    padding: 50px;
  }

  &__logo-txt {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 80px;
  }

  &__logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__txt {
    font-size: clamp(0.8rem, 1vw, 1.2rem);
    color: u.$color-white;
    line-height: 1.5;
  }

  &__logo img {
    width: 50%;
    height: 50%;
    object-fit: contain;
    object-position: center;
  }

  &__overlay {
    top: 0;
    position: fixed;
    background-color: rgba(92, 92, 92, 0.418);
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  &__overlay-box {
    background-color: u.$main-blue;
    width: 60%;
    margin: 0 auto;
  }

  &__overlay-txt {
    font-size: clamp(2rem, 3vw, 3rem);
    padding: 0px 10px 45px 10px;
    text-align: center;
    font-family: "helveticaNeueLtStd-Ex";
    color: white;
    line-height: 1.5;
    letter-spacing: 1.2px;
  }

  &__overlay-btn-box {
    display: flex;
    justify-content: flex-end;
    transform: translateY(-45px);
  }

  &__overlay-btn {
    font-size: clamp(1.5rem, 3vw, 3rem);
    background-color: transparent;
    border: none;
    color: white;
  }
}

input::placeholder {
  color: rgba(209, 209, 209, 0.555);
}

textarea::placeholder {
  color: rgba(209, 209, 209, 0.555);
}
