.five-part {
  width: 100vw;
  max-height: 100vh;

  &__container {
    min-width: 0;
    min-height: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 50vh 50vh;
    grid-template-areas:
      "b1 b2"
      "b1 b3";
  }

  &__img-box {
    min-width: 0;
    min-height: 0;
    // height: 50vh;
    position: relative;
  }

  &__txt-box {
    left: 40px;
    bottom: 40px;
    position: absolute;
  }

  &__txt-box p {
    color: white;
    font-family: "helveticaNeueLtStd-Ex";
  }

  &__txt-box p:nth-child(1) {
    font-size: clamp(1rem, 1vw, 1.5rem);
    margin-bottom: 5px;
    text-decoration: underline;
    letter-spacing: 1px;
  }

  &__txt-box p:nth-child(2) {
    font-size: clamp(0.3rem, 0.5vw, 1rem);
    letter-spacing: 1.5px;
  }

  &__img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__img-box {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__video-box {
    width: 100%;
    height: 100%;
  }

  &__video-box video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  // &__lottie-player {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   object-position: center;
  // }

  &__img-box--one {
    grid-area: b1;
  }

  &__img-box--two {
    grid-area: b2;
  }

  &__img-box--three {
    max-height: 100%;
    grid-area: b3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50vh;
  }

  &__img-subgrid-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__img-sub {
    position: relative;
  }
}
