.hero-container {
  height: calc(100vh - 122px);
  position: relative;
  margin-top: 122px;
  &__img-box {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transform: scale(1.4);
  }

  &__main-txt-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__second-txt-container {
    width: 30%;
    height: 30%;
    position: relative;
  }

  &__txt {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(2rem, 4vw, 4rem);
    color: white;
    letter-spacing: 1.2px;
    display: none;
  }

  &__txt--one {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  &__txt--two {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  &__txt--three {
    font-family: "helveticaNeueLtStd-Ex";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
