@use "../util" as u;

@media screen and (max-width: 2560px) {
  .inner-page {
    &__grid {
      grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
      grid-auto-rows: 800px;
    }

    &__img-box img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media screen and (max-width: 1920px) {
  .inner-page {
    &__grid {
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
      grid-auto-rows: 600px;
    }
  }
}

@media screen and (max-width: 1305px) {
  body {
    cursor: auto;
  }
  .six-part {
    &__main-img-box {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    &__main-txt-container {
      flex-basis: 90%;
    }
  }
}

@media screen and (max-width: 900px) {
  .six-part {
    &__main-txt-container {
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 700px) {
  .header-box {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .nav-box {
    display: none;
  }
  .nav-box-mobile {
    display: block;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 0;
    background-color: u.$main-blue;
    z-index: 3;
    transition: height 1s ease;
  }

  .nav-box-mobile.add-height {
    position: fixed;
    height: 100%;
    transition: height 1s ease;
  }

  .nav-hamberger {
    display: block;
    width: 20px;
    height: 10px;
    position: relative;
  }

  .nav-box-mobile {
    overflow-y: hidden;
  }

  .nav-box-mobile-nav {
    padding: 80px 40px;
    opacity: 0;
    transition: opacity 2s ease;
  }

  .nav-box-mobile-nav.display-one {
    opacity: 100%;
    transition: opacity 2s ease;
  }

  .nav-box-mobile ul {
    display: flex;
    gap: 60px;
    flex-direction: column;
  }

  .nav-box-mobile li {
    list-style: none;
  }

  .nav-box-mobile a {
    font-family: "helveticaNeueLtStd-Ex";
    font-size: clamp(1.2rem, 1.5vw, 3rem);
    color: white;
    text-decoration: none;
  }

  .third-part__cursormover {
    display: none;
  }

  .five-part {
    &__container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 30vh);
      grid-template-areas: initial;
    }

    &__img-box--one {
      grid-area: initial !important;
    }

    &__img-box--two {
      grid-area: initial !important;
    }

    &__img-box--three {
      grid-area: initial !important;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 20vh);
    }
  }

  .six-part {
    &__main {
      flex-direction: column;
    }
  }

  .seven-part {
    margin-top: 60px;
    &__img-container {
      flex-direction: column;
      justify-content: center;
      gap: 0;
    }

    &__img-box {
      height: 200px;
    }
  }

  .footer {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }

    &__text p:nth-child(1) {
      text-align: center;
    }

    &__text p:nth-child(2) {
      text-align: center;
    }
  }

  .custome-cursor {
    display: none;

    &__inner {
      display: none;
    }
  }

  .mywork {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .packaging-inner-page {
    &__grid {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    &__img-box--one {
      width: min(100%, 1000px);
      height: 300px;
    }

    &__img-box--two {
      width: min(100%, 1500px);
      height: 300px;
      margin-bottom: 20px;
    }

    &__img-box--one img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__img-box--two img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .video-editing {
    &__second-img-box {
      display: flex;
      flex-direction: column;
    }

    &__video-play-btn {
      width: 50px;
      height: 50px;
    }

    &__txt-box {
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;
    }
  }

  .contact {
    &__form-box {
      flex-direction: column;
    }

    &__logo-txt {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  .about {
    &__container {
      flex-direction: column;
    }
  }

  .inner-page {
    &__txt-box {
      flex-direction: column;
    }

    &__grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    &__overlapfixed-closed-btn p {
      transform: translateX(0);
    }
  }
}
